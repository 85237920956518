'use strict';

angular.module('enervexSalesappApp').controller('FansCtrl', function($scope, Fan, _) {
	$scope.allFans = [];
	$scope.offset = 0;
	$scope.limit = 50;
	$scope.ctx = {
		DRY: true,
		COM: true,
		BWH: true
	}
	function fetch() {
		Fan.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(fans){
			if (fans.length > 0) {
				var id = 0;
				_.each(fans, function(fan){
					fan.details = [
						{
							entry: "Categories: "+_.pluck(fan.fanCategories,"name").join(","),
							id: id++
						},
						{
							entry: "Condensing: "+fan.Condensing,
							id: id++
						},
						{
							entry: "DesSP: "+fan.DesSP,
							id: id++
						},
						{
							entry: "Sidewall: "+fan.Sidewall,
							id: id++
						},
						{
							entry: "Include Combustion: "+fan.includeCombustionAir,
							id: id++
						},
						{
							entry: "MaxTemp: "+fan.MaxTemp,
							id: id++
						},
						{
							entry: "MaxVol: "+fan.MaxVol,
							id: id++
						},
						{
							entry: "Sidewall: "+fan.Sidewall,
							id: id++
						},
						{
							entry: "SolidFuel: "+fan.SolidFuel,
							id: id++
						},
						{
							entry: "Vertical: "+fan.Vertical,
							id: id++
						}
					];
					fan.detailString = _.pluck(fan.details, "entry").join(" ");
				})
				$scope.allFans = $scope.allFans.concat(fans);
				$scope.offset = $scope.offset + $scope.limit;
				$scope.allFans = _.sortBy($scope.allFans, function(bomRule){
					return bomRule.sortOrder * 1
				})
				fetch();
			} else {
				$scope.fans = $scope.allFans
			}
		});
	}
	$scope.computeAses = [
		"BWH",
		"DRY",
		"COM"
	]
	fetch();
	$scope.delete = function(bomRule) {
		Fan.delete({
			id: bomRule._id
		});
		$scope.allFans = _.filter($scope.allFans, function(r){
			return r._id != bomRule._id
		})
		$scope.applyFilter()
	}
	$scope.fanCategoryNames = function(fan) {
		return _.pluck(fan.fanCategories, "name").join(",")
	}
	$scope.applyFilter = function() {
		var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

		$scope.fans = _.filter($scope.allFans, function(fan){
			if (nameReg && !(nameReg.test(fan.code) || nameReg.test(fan.detailString)) ) {
				return false;
			} else if ($scope.ctx.MaxVol && fan.MaxVol < $scope.ctx.MaxVol){
				return false;
			} else if ($scope.ctx.DesSP && fan.DesSP < $scope.ctx.DesSP){
				return false;
			} else if ($scope.ctx.Category && $scope.ctx.Category != "" && $scope.ctx.Category!= "*" && fan.Category != $scope.ctx.Category){
				return false;
			} else if ($scope.computeAs== 'BWH' && !fan.BWH) {
				return false;
			} else if (($scope.ctx.active== 'true' && !fan.active) || ($scope.ctx.active== 'false' && fan.active)) {
				return false;
			} else if (($scope.ctx.Vertical== 'true' && !fan.Vertical) || ($scope.ctx.Vertical== 'false' && fan.Vertical)) {
				return false;
			} else if (($scope.ctx.Sidewall== 'true' && !fan.Sidewall) || ($scope.ctx.Sidewall== 'false' && fan.Sidewall)) {
				return false;
			} else if (($scope.ctx.includeCombustionAir== 'true' && !fan.includeCombustionAir) || ($scope.ctx.includeCombustionAir== 'false' && fan.includeCombustionAir)) {
				return false;
			} else if ($scope.computeAs== 'COM' && !fan.COM) {
				return false;
			} else if ($scope.computeAs== 'DRY' && !fan.DRY) {
				return false;
			} else {
				return true;
			}
		})
	}
});
